import PermissionsTable from "@/components/help-center/custom-tags/PermissionsTable.vue";

export const permissionsTable = {
  render: PermissionsTable,
  attributes: {
    marginStyles: {
      type: String,
    },
    permissions: {
      type: Array,
    },
  },
};
