<template>
  <div :class="marginStyles" class="flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              ></th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Owner
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Billing
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Member
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="(permission, index) in renderedPermissions"
              :key="index"
              class="even:bg-gray-50"
            >
              <td
                class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
              >
                <component
                  :is="permission.renderedMarkdown"
                  id="tabbed-container-markdown"
                  class="prose max-w-none prose-sm prose-a:text-indigo-700 even:prose-tr:bg-gray-50"
                />
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <CheckIcon v-if="permission.ownerAllowed" class="h-4 w-4" />
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <CheckIcon v-if="permission.billingAllowed" class="h-4 w-4" />
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <CheckIcon v-if="permission.memberAllowed" class="h-4 w-4" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/solid";
import Markdoc from "@markdoc/markdoc";
import render from "vue-markdoc";
import { config } from "@/assets/documentation/markdoc.config";
import { onMounted, markRaw, shallowRef } from "vue";
import _ from "lodash";

const props = defineProps(["permissions", "marginStyles"]);

const renderedPermissions = shallowRef([]);

onMounted(() => {
  renderPermissionMarkdown();
});

function renderPermissionMarkdown() {
  renderedPermissions.value = props.permissions.map((permission) => {
    const ast = Markdoc.parse(permission.markdown);

    const content = Markdoc.transform(ast, config);

    return _.merge({}, permission, {
      renderedMarkdown: markRaw(render(content)),
    });
  });
}
</script>
