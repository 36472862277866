import TabbedContainer from "@/components/help-center/custom-tags/TabbedContainer.vue";

export const tabbedContainer = {
  render: TabbedContainer,
  attributes: {
    tabs: {
      type: Array,
      default: [],
      required: true,
    },
    marginStyles: {
      type: String,
    },
  },
};
