<template>
  <div :class="marginStyles">
    <div :class="[helpViews ? 'hidden' : '']">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex flex-wrap space-x-2" aria-label="Tabs">
          <button
            v-for="tab in tabs"
            :key="tab.name"
            @click="currentTab = tab"
            type="button"
            :class="[
              tab.name === currentTab?.name
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-bold',
            ]"
            :aria-current="tab.name === currentTab?.name ? 'page' : undefined"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>
    <div :class="[helpViews ? 'block' : 'hidden']">
      <nav class="flex" aria-label="Tabs">
        <button
          v-for="tab in tabs"
          :key="tab.name"
          @click="currentTab = tab"
          type="button"
          :class="[
            tab.name === currentTab?.name
              ? '-my-0.5 bg-white text-indigo-700 border-t border-l border-r border-gray-300'
              : 'text-gray-500 hover:text-gray-700',
            'rounded-t-md px-3 py-2 text-sm font-medium',
          ]"
          :aria-current="tab.name === currentTab?.name ? 'page' : undefined"
        >
          {{ tab.name }}
        </button>
      </nav>
    </div>
    <div class="px-3 py-4 rounded-md border border-gray-300">
      <component
        v-if="currentTab"
        :is="ContentComponent"
        id="tabbed-container-markdown"
        class="prose max-w-none prose-sm prose-a:text-indigo-700 even:prose-tr:bg-gray-50"
      />
    </div>
  </div>
</template>

<script setup>
import Markdoc from "@markdoc/markdoc";
import render from "vue-markdoc";
import { config } from "@/assets/documentation/markdoc.config";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import _ from "lodash";

import { ref, onMounted, watch, markRaw } from "vue";

const props = defineProps(["tabs", "marginStyles"]);

const currentTab = ref(null);
const ContentComponent = ref(null);

const documentationStore = useDocumentationStore();
const { helpViews } = storeToRefs(documentationStore);

watch(currentTab, () => {
  refreshMarkdown();
});

onMounted(() => {
  currentTab.value = _.head(props.tabs);
});

function refreshMarkdown(timeout = 50) {
  setTimeout(() => {
    const ast = Markdoc.parse(currentTab.value?.markdown);

    const content = Markdoc.transform(ast, config);
    ContentComponent.value = markRaw(render(content));
  }, timeout);
}
</script>
