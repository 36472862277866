import { callout } from "@/assets/documentation/custom-tags/Callout.markdoc";
import { glossaryAlphabetLinks } from "@/assets/documentation/custom-tags/GlossaryAlphabetLinks.markdoc";
import { inlineAppIcon } from "@/assets/documentation/custom-tags/InlineAppIcon.markdoc";
import { inlineReputationIcon } from "@/assets/documentation/custom-tags/InlineReputationIcon.markdoc";
import { inlineKeyboardShortcut } from "@/assets/documentation/custom-tags/InlineKeyboardShortcut.markdoc";
import { inlineDatapoint } from "@/assets/documentation/custom-tags/InlineDatapoint.markdoc";
import { inlineVideo } from "@/assets/documentation/custom-tags/InlineVideo.markdoc";
import { inlineRouterLink } from "@/assets/documentation/custom-tags/InlineRouterLink.markdoc";
import { inlinePropertyDiagramIcon } from "@/assets/documentation/custom-tags/InlinePropertyDiagramIcon.markdoc";
import { tabbedContainer } from "@/assets/documentation/custom-tags/TabbedContainer.markdoc";
import { tablessContainer } from "@/assets/documentation/custom-tags/TablessContainer.markdoc";
import { testableUnlocker } from "@/assets/documentation/custom-tags/TestableUnlocker.markdoc";
import { validationPipeline } from "@/assets/documentation/custom-tags/ValidationPipeline.markdoc";
import { iconWellContainer } from "@/assets/documentation/custom-tags/IconWellContainer.markdoc";
import { permissionsTable } from "@/assets/documentation/custom-tags/PermissionsTable.markdoc";
import { involvementSquare } from "@/assets/documentation/custom-tags/InvolvementSquare.markdoc";
import { styleableDiv } from "@/assets/documentation/custom-tags/StyleableDiv.markdoc";
import { beforeAfterCapitalStack } from "@/assets/documentation/custom-tags/BeforeAfterCapitalStack.markdoc";
import { beforeAfter } from "@/assets/documentation/custom-tags/BeforeAfter.markdoc";
import {
  mapHighlights,
  datapointHighlights,
  sidebarHighlights,
  quickActionsHighlights,
} from "@/assets/documentation/articles/howToUseTowerHuntQuickStart";
import { createAreaOptions } from "@/assets/documentation/articles/mapExploredAreas";
import {
  propertyMarkerExamples,
  landCoveringMarkerExamples,
  landParcelMarkerExamples,
  mapMarkerPopUp,
} from "@/assets/documentation/articles/mapMarkers";
import { surveyRowInteractions } from "@/assets/documentation/articles/surveyProperties";
import { filterSectionVariants } from "@/assets/documentation/articles/theRoleOfTheAnalyzeTab";
import { propertyExamples } from "@/assets/documentation/articles/whatIsAProperty";
import { datapointExamples } from "@/assets/documentation/articles/whatIsADatapoint";
import { timelineHighlights } from "@/assets/documentation/articles/timeline";
import { customizeTeamHighlights } from "@/assets/documentation/articles/gettingStartedForTowerHuntAdmins";
import { permissionList } from "@/assets/documentation/articles/permissionsByRoleInTowerHunt";
import { addFeeParcelVisuals } from "@/assets/documentation/articles/diagram-interactions/addFeeParcel";
import {
  feeLeaseholdVisuals,
  feeSubLeaseholdVisuals,
  condoLeaseholdVisuals,
  condoSubLeaseholdVisuals,
} from "@/assets/documentation/articles/diagram-interactions/addLeasehold";
import { addReciprocalEasementVisuals } from "@/assets/documentation/articles/diagram-interactions/addReciprocalEasement";
import {
  addBuildingVisuals,
  addSurfaceCoveringVisuals,
} from "@/assets/documentation/articles/diagram-interactions/addLandCovering";
import {
  addLandCoveringFloorAreaVisuals,
  addBuildingLevelFloorAreaVisuals,
  addExistingAirRightsFloorAreaVisuals,
  addAirRightsFloorAreaVisuals,
} from "@/assets/documentation/articles/diagram-interactions/addFloorArea";
import { groupLandCoveringVisuals } from "@/assets/documentation/articles/diagram-interactions/groupLandCoverings";
import { addEnhancementVisuals } from "@/assets/documentation/articles/diagram-interactions/addEnhancement";
import { shiftFeeParcelVisuals } from "@/assets/documentation/articles/diagram-interactions/shiftFeeParcel";
import { shiftLandCoveringVisuals } from "@/assets/documentation/articles/diagram-interactions/shiftLandCovering";
import {
  addLandCoveringUnitVisuals,
  addFloorAreaUnitVisuals,
} from "@/assets/documentation/articles/diagram-interactions/addOwnershipUnit";
import { addBuildingLevelVisuals } from "@/assets/documentation/articles/diagram-interactions/addBuildingLevels";
import { relocateLandCoveringVisuals } from "@/assets/documentation/articles/diagram-interactions/relocateLandCovering";
import { setSameWidthBuildingLevelsVisuals } from "@/assets/documentation/articles/diagram-interactions/setSameWidthBuildingLevels";
import { insertBuildingLevelVisuals } from "@/assets/documentation/articles/diagram-interactions/insertBuildingLevels";
import {
  justifyBuildingLevelVisuals,
  justifyBuildingLevelArchwayVisuals,
  justifyBuildingLevelSplitVisuals,
} from "@/assets/documentation/articles/diagram-interactions/justifyBuildingLevels";
import {
  raiseGroundVisuals,
  lowerGroundVisuals,
} from "@/assets/documentation/articles/diagram-interactions/raiseLowerGround";
import { splitFloorAreaVisuals } from "@/assets/documentation/articles/diagram-interactions/splitFloorArea";
import { relocateFloorAreaVisuals } from "@/assets/documentation/articles/diagram-interactions/relocateFloorArea";
import { horizontallyReorderFloorAreaVisuals } from "@/assets/documentation/articles/diagram-interactions/horizontallyReorderFloorArea";
import { verticallyReorderFloorAreaVisuals } from "@/assets/documentation/articles/diagram-interactions/verticallyReorderFloorArea";
import { floorAreaVerticalOpeningVisuals } from "@/assets/documentation/articles/diagram-interactions/floorAreaVerticalOpening";
import { relocateOwnershipUnitVisuals } from "@/assets/documentation/articles/diagram-interactions/relocateOwnershipUnit";
import { addFloorAreaLayoutVisuals } from "@/assets/documentation/articles/diagram-interactions/addFloorAreaLayout";
import { taskDashboardHighlights } from "@/assets/documentation/articles/tasksQuickStart";
import { createInvestmentForm } from "@/assets/documentation/articles/createAnInvestment";
import { signInFormFactors } from "@/assets/documentation/articles/signInToTowerHunt";

const tags = {
  callout,
  glossaryAlphabetLinks,
  inlineAppIcon,
  inlineReputationIcon,
  inlineKeyboardShortcut,
  inlineRouterLink,
  inlinePropertyDiagramIcon,
  inlineDatapoint,
  inlineVideo,
  tabbedContainer,
  tablessContainer,
  testableUnlocker,
  validationPipeline,
  iconWellContainer,
  permissionsTable,
  involvementSquare,
  styleableDiv,
  beforeAfter,
  beforeAfterCapitalStack,
};
const variables = {
  mapHighlights,
  datapointHighlights,
  sidebarHighlights,
  quickActionsHighlights,
  customizeTeamHighlights,
  timelineHighlights,
  permissionList,
  createAreaOptions,
  propertyMarkerExamples,
  landCoveringMarkerExamples,
  landParcelMarkerExamples,
  mapMarkerPopUp,
  surveyRowInteractions,
  filterSectionVariants,
  propertyExamples,
  datapointExamples,
  addFeeParcelVisuals,
  feeLeaseholdVisuals,
  feeSubLeaseholdVisuals,
  condoLeaseholdVisuals,
  condoSubLeaseholdVisuals,
  addReciprocalEasementVisuals,
  addBuildingVisuals,
  addSurfaceCoveringVisuals,
  addLandCoveringFloorAreaVisuals,
  addBuildingLevelFloorAreaVisuals,
  addExistingAirRightsFloorAreaVisuals,
  addAirRightsFloorAreaVisuals,
  groupLandCoveringVisuals,
  addEnhancementVisuals,
  shiftFeeParcelVisuals,
  shiftLandCoveringVisuals,
  addLandCoveringUnitVisuals,
  insertBuildingLevelVisuals,
  addFloorAreaUnitVisuals,
  addBuildingLevelVisuals,
  relocateLandCoveringVisuals,
  setSameWidthBuildingLevelsVisuals,
  justifyBuildingLevelVisuals,
  justifyBuildingLevelArchwayVisuals,
  justifyBuildingLevelSplitVisuals,
  raiseGroundVisuals,
  lowerGroundVisuals,
  splitFloorAreaVisuals,
  relocateFloorAreaVisuals,
  horizontallyReorderFloorAreaVisuals,
  verticallyReorderFloorAreaVisuals,
  floorAreaVerticalOpeningVisuals,
  relocateOwnershipUnitVisuals,
  addFloorAreaLayoutVisuals,
  taskDashboardHighlights,
  createInvestmentForm,
  signInFormFactors,
};

export const config = {
  tags,
  variables,
};
